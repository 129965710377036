<template>
  <div class="container py-5">
    <!-- Header Text -->
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h1 class="text-center mb-5">API Data Extractor</h1>
        <p class="text-center mb-4">
          The Data Extractor is still being built, but a demo alpha version of it is available. <br>
          If you want to test it, insert a basic API without authentication and see what it can do. <br>
          Only fetching data (GET) is available for now. <br>
          Need API ideas? You can browse free public API's <a href="https://apipheny.io/free-api/" target="_blank"><u>here</u></a> if you are interested. <br>
          Want some specific options to the tool? Be in touch through the contact form or my other socials.
        </p>
      </div>
    </div>
    
    <!-- Form -->
    <!--
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <form @submit.prevent="newFactFromAI">
          <div class="row g-3">
            <div class="col-12 form-group">
              <label for="questionInput" class="form-label">Ask a question</label>
              <input
                type="text"
                id="questionInput"
                v-model="userQuestion"
                class="form-control custom-input"
                placeholder="Enter your question"
                required
              />
            </div>
          </div>
          <div class="text-center mt-4">
            <button type="submit" class="btn w-100">Ask AI</button>
          </div>
        </form>
      </div>
    </div>
    -->

    <!-- AI Response -->
    <!--
    <div class="row justify-content-center mt-4">
      <div class="col-lg-8">
        <div class="card fact-box">
          <div class="card-body">
            <h5 class="card-title text-center mb-4" v-if="!apiError">AI Response</h5>
            <h5 class="card-title text-center mb-4" v-else>{{ apiError }}</h5>
            <p v-if="fact !== null" class="text-center">{{ fact }}</p>
            <p v-else class="text-center" style="color: transparent;">
              Fetching response from OpenAI API...
            </p>
            <p class="word-counter" v-if="fact !== null">{{ wordCount }} words</p>
          </div>
        </div>
      </div>
    </div>
    -->

    <!-- New API Mapper Section -->
    <div class="row justify-content-center mt-5">
      <div class="">
        <form @submit.prevent="fetchMappings" class="">
          <label for="apiEndpoint" class="form-label">API Endpoint</label> 
            <small class="float-end" style="font-size: 0.8em;"><a href="https://apipheny.io/free-api/" target="_blank">Need API ideas? Click here</a></small>
          <input
            type="text"
            id="apiEndpoint"
            v-model="apiEndpoint"
            class="form-control custom-input"
            placeholder="Enter an API endpoint"
            required
          />
          <div class="text-center mt-4">
            <button type="submit" class="btn full-width-btn w-100">Execute</button>
          </div>
        </form>

        <!-- Disclaimer that it's an AI generating code and making mapping suggestions, so it can make mistakes. Also that abuse of the tool is forbidden -->
        <small>Disclaimer: The code and mapping suggestions are generated by AI and may contain errors. Abuse of this tool is forbidden.</small>

        <!-- Two info boxes side by side -->
        <div class="row mt-4" ref="mappingSection">
          <!-- Left box: Raw JSON display -->
          <div class="col-md-8 position-relative" v-if="!rawJson"> <!-- waiting for API input to analyze -->
            <textarea
              class="form-control code-display"
              :value="rawJsonPlaceholder"
              readonly
            ></textarea>
          </div>
          <div class="col-md-8 position-relative" v-if="rawJson"> <!-- API input has been given json raw data is back -->
            <button
              class="btn btn-sm btn-light position-absolute"
              style="top: 8px; right: 20px;"
              @click="copyRawJson"
            >
              {{ copyStatusJson || 'Copy' }}
            </button>
            <textarea
              class="form-control code-display"
              :value="rawJson"
              readonly
            ></textarea>
          </div>
          
          <!-- Right box: analyzing / suggestions -->
          <div class="col-md-4" v-if="!isAnalyzing && mappingSuggestions.length === 0">
            <div class="form-control code-display text-center py-5">
              Waiting for API to analyze...
            </div>
          </div>
          <div class="col-md-4" v-if="isAnalyzing">
            <div class="form-control code-display text-center py-5">
              Analyzing...
            </div>
          </div>
          <div class="col-md-4" v-else-if="mappingSuggestions.length > 0">
            <div class="form-control code-display">
              <label for="mappingSelect">Select a Mapping:</label>
              <select
                id="mappingSelect"
                class="form-select mt-2"
                v-model="selectedMapping"
              >
                <option value="" disabled>Select a mapping suggestion</option>
                <option v-for="(suggestion, i) in mappingSuggestions" :key="i">
                  {{ suggestion }}
                </option>
              </select>
              <label for="languageSelect" class="mt-3">Select a Language:</label>
              <select
                id="languageSelect"
                class="form-select mt-2"
                v-model="selectedLanguage"
              >
                <option value="" disabled>Select a programming language</option>
                <option v-for="(lang, idx) in languages" :key="idx">{{ lang }}</option>
              </select>

              <button class="btn full-width-btn mt-3 w-100" @click="generateMappingCode">
                Generate code
              </button>
            </div>
          </div>
        </div>

        <!-- Optional code output below -->
        <div class="row mt-4 position-relative" v-if="mappingCode" ref="codeSection">
          <div class="col-12">
            <button
              class="btn btn-sm btn-light position-absolute copy-btn"
              style="top: 8px; right: 20px;"
              @click="copyCode"
            >
              {{ copyStatusCode || 'Copy' }}
            </button>
            <textarea
              class="form-control code-display"
              :value="mappingCode"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'APIDataExtractorView',
  data() {
    return {
      // fact: null,
      // userQuestion: '',
      apiError: null,
      apiEndpoint: '',
      mappingSuggestions: [],
      rawJson: '',
      isAnalyzing: false,
      selectedMapping: '',
      copyStatusJson: '',
      copyStatusCode: '',
      rawJsonPlaceholder: '{\n  "fact": "Still no data to show here",  \n  "length": 26\n}',
      selectedLanguage: '',
      languages: ['JavaScript', 'Python', 'C#', 'Java', 'Ruby', 'Go', 'PHP', 'TypeScript', 'Swift', 'Kotlin'],
      mappingCode: ''
    }
  },
  computed: {
    // wordCount() {
    //   return this.fact ? this.fact.split(' ').length : 0;
    // }
  },
  methods: {
    // newFactFromAI() {
    //   const dev = window.location.hostname === 'localhost';
    //   const baseUrl = dev ? 'http://localhost:8888' : '';
    //   fetch(`${baseUrl}/.netlify/functions/ai`, {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ prompt: this.userQuestion })
    //   })
    //   .then(res => res.json())
    //   .then(data => {
    //     this.fact = data.message || 'No response received.';
    //   })
    //   .catch(err => {
    //     console.error('Error fetching AI response:', err);
    //     this.fact = null;
    //     this.apiError = 'Something went wrong with asking AI...';
    //   });
    // },
    async fetchMappings() {
      this.mappingSuggestions = [];
      this.rawJson = '';
      this.isAnalyzing = true;

      try {
        const res = await fetch('/.netlify/functions/ai_DataMapperSuggest', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ endpoint: this.apiEndpoint })
        });
        const data = await res.json();
        if (data.error) {
          this.rawJson = data.error;
        } else {
          try {
            const parsed = JSON.parse(data.rawData || '{}');
            this.rawJson = JSON.stringify(parsed, null, 2);
          } catch (err) {
            this.rawJson = data.rawData || '';
          }
          this.mappingSuggestions = data.suggestions || [];
        }
        this.scrollToSection('mappingSection');
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isAnalyzing = false;
      }
    },
    async generateMappingCode() {
      if (!this.selectedLanguage || !this.selectedMapping) return;
      try {
        const res = await fetch('/.netlify/functions/ai_GenerateMappingCode', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            endpoint: this.apiEndpoint,
            selectedMapping: this.selectedMapping,
            language: this.selectedLanguage
          })
        });
        const data = await res.json();
        this.mappingCode = data.code || '';
        this.scrollToSection('codeSection');
      } catch (err) {
        console.error('Error generating code:', err);
      }
    },
    scrollToSection(section) {
      if (window.innerWidth <= 768) { // Only apply on mobile devices
        this.$nextTick(() => {
          const element = this.$refs[section];
          if (element) {
            const offset = element.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2) + (element.clientHeight / 2);
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        });
      }
    },
    copyRawJson() {
      if (!this.rawJson) return;
      navigator.clipboard.writeText(this.rawJson)
        .then(() => {
          this.copyStatusJson = 'Copied!';
          setTimeout(() => {
            this.copyStatusJson = '';
          }, 2000);
        })
        .catch(console.error);
    },
    copyCode() {
      if (!this.mappingCode) return;
      navigator.clipboard.writeText(this.mappingCode)
        .then(() => {
          this.copyStatusCode = 'Copied!';
          setTimeout(() => {
            this.copyStatusCode = '';
          }, 2000);
        })
        .catch(console.error);
    }
  }
};
</script>

<style scoped>
.custom-input {
  background-color: var(--cc_Form);
  color: var(--cc_third);
  border: none;
  border-bottom: 2px solid var(--cc_third);
  margin-bottom: 1px;
}
.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.custom-input:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 3px solid var(--cc_second);
  margin-bottom: -1px;
}

.fact-box {
  background-color: var(--cc_Form);
  color: var(--cc_third);
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
}

.word-counter {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.9rem;
  color: var(--cc_third);
}

.code-display {
  font-family: "Source Code Pro", monospace;
  resize: none;
  min-height: 400px;
  background-color: var(--cc_Form);
  color: var(--cc_third);
  margin-bottom: 1rem;
  /* Custom scrollbar styles */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: var(--cc_third) var(--cc_Form); /* Firefox */
}
.code-display::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
.code-display::-webkit-scrollbar-track {
  background: var(--cc_Form); /* Background of the scrollbar track */
}
.code-display::-webkit-scrollbar-thumb {
  background-color: var(--cc_third); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid var(--cc_Form); /* Padding around the scrollbar thumb */
}

.copy-btn {
  width: auto !important;
}

@media (max-width: 768px) {
  .custom-input,
  .full-width-btn {
    width: 100% !important;
    display: block;
  }
  .code-display {
    font-size: 0.8rem; /* Smaller font size for small screens */
  }
  .form-select {
    white-space: normal; /* Ensure long options wrap to the next line */
    word-wrap: break-word; /* Ensure long options break to the next line */
  }
}
</style>
