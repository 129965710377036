<template>
  <div class="tooltip-container" @click="toggleTooltip" @mouseleave="show = false">
    <slot></slot>
    <div v-if="show" class="tooltip-box">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleTooltip() {
      this.show = !this.show; //Toggles tooltip visibility on click
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-box {
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  z-index: 10;
  white-space: nowrap;
}

.tooltip-box::after {
  content: '';
  position: absolute;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}
</style>
