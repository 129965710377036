<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h1 class="text-center mb-5">Get in touch</h1>
        <p class="text-center mb-4">
          Hey there, fill out this form with any questions you may or may not have <br>
          You can also contact me through my other socials (found in the footer)
        </p>
        <p class="text-center mb-5">I will reach out to you as soon as possible</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <form 
          name="contactForm3"
          data-netlify="true"
          netlify-honeypot="bot-field"
          @submit.prevent="submitForm"
        >
          <input type="hidden" name="form-name" value="contactForm3" />

          <!-- Honeypot anti-spam field -->
          <p style="display:none;">
            <label>Don't fill this out: <input name="bot-field" /></label>
          </p>

          <div class="row g-3">
            <div class="col-md-6 form-group">
              <label for="nameInput" class="form-label">NAME</label>
              <input
                type="text"
                id="nameInput"
                name="name"
                class="form-control custom-input"
                placeholder="Enter your name"
                required
              />
            </div>

            <div class="col-md-6">
              <label for="emailInput" class="form-label">EMAIL ADDRESS</label>
              <input
                type="email"
                id="emailInput"
                name="email"
                class="form-control custom-input"
                placeholder="Enter your email"
                required
              />
            </div>
          </div>

          <div class="row g-3 mt-3">
            <div class="col-12">
              <label for="message" class="form-label">MESSAGE</label>
              <textarea
                id="message"
                name="message"
                class="form-control custom-input"
                rows="4"
                placeholder="Enter your message"
                required
              ></textarea>
            </div>
          </div>

          <div class="text-center mt-4">
            <button type="submit" class="btn w-100">SEND</button>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div v-if="showSuccessPopup" class="popup-backdrop" @click="closePopup">
    <div class="popup-content" @click.stop>
      <i class="bi bi-check-circle-fill checkmark"></i>
      <h2>Form Received!</h2>
      <p>Thank you for reaching out! We'll get back to you as soon as possible.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactView',
  data() {
    return {
      showSuccessPopup: false
    }
  },
  methods: {
    submitForm(event) {
      const form = event.target;
      const formData = new FormData(form);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
      })
      .then(() => {
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
        }, 6000);
      })
      .catch((error) => {
        console.error("Form submission error", error);
        alert("Oops! Something went wrong, please try again.");
      });
    },
    closePopup() {
      this.showSuccessPopup = false;
    }
  }
}
</script>

<style scoped>
.custom-input {
  background-color: var(--cc_Form);
  color: var(--cc_third);
  border: none;
  border-bottom: 2px solid var(--cc_third);
  margin-bottom: 1px;
}
.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.custom-input:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 3px solid var(--cc_second);
  margin-bottom: -1px;
}
.popup-backdrop {
  position: fixed;
  top: 0; left: 0;
  width: 100vw; height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex; align-items: center; justify-content: center;
  z-index: 9999;
}
.popup-content {
  background-color: var(--cc_Form);
  border: 3px solid var(--cc_second);
  color: var(--cc_third);
  padding: 2rem; border-radius: 1rem;
  text-align: center; width: 300px;
  animation: popup-scale 0.3s ease-in;
}
.checkmark {
  font-size: 4rem; color: #34A853;
  margin-bottom: 0.5rem;
}
@keyframes popup-scale {
  from { transform: scale(0.5); }
  to { transform: scale(1); }
}
</style>
